nav ul {
	display: flex;
	flex-flow: row wrap;
	padding-left: 0;
	margin-left: -0.5rem;

	li {
		list-style: none;
		display: block;
		margin: 0 0.5rem;
		border: 1px solid black;

		&.active {
			background-color: #5294a1;
			color: white;
		}

		span,
		a {
			padding: 0.5rem 1rem;
			display: block;
			text-decoration: none;
		}

		a {
			color: black;
		}

		span {
			cursor: default;
		}
	}
}
