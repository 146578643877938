html {
	background: #eee;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
		"Segoe UI Emoji", "Segoe UI Symbol", "Lato", "Helvetica Neue", Helvetica, Arial,
		sans-serif;
	font-size: 14px;
}

body {
	max-width: 1024px;
	margin: 1rem auto;
	background: white;
	padding: 1rem;
}

.delete-button {
	height: 1rem;
	padding: 0;
	line-height: 0;
	padding: 0.5rem;
}

.nav-logo {
	display: flex;
	align-items: center;
}

@import "includes.scss";
